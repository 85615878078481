import React, { useEffect, useState } from 'react';
import { utils, write } from 'xlsx';

const ConvertJsonToXlsx = ({ jsonData }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(jsonData);
  }, [jsonData]);

  const downloadXlsx = () => {
    const workbook = utils.book_new();

    // Prepare header row
    const header = [
      "Status(*)",
      "ItemType(*)",
      "StoreName(*)",
      "MerchantOrderId",
      "RecipientName(*)",
      "RecipientPhone(*)",
      "RecipientCity(*)",
      "RecipientZone(*)",
      "RecipientArea",
      "RecipientAddress(*)",
      "AmountToCollect(*)",
      "ItemQuantity(*)",
      "ItemWeight(*)",
      "ItemDesc",
      "SpecialInstruction",
    ];

    // Prepare data for XLSX
    const worksheetData = [header, ...data.map(order => [
      order.orderStatus,
      // order.selectedItemsDetailArr[0]?.individualProductArr[0]?.productType,
      "Parcel",
      // order.clientbrandName || "",
      "",
      order._id,
      order.name,
      order.phone.toString(), // Convert to string as per your requirement
      order.districts,
      order.zones,
      order.areas,
      order.address,
      order.collectAmount,
      order.grandQuantity,
      "0.3kg", // Assuming no weight information provided
      Array.isArray(order.selectedItemsDetailArr) ?
        order.selectedItemsDetailArr
          .map((item) => {
            const product = item.individualProductArr[0];
            return ` ${product.productType}: ${product.color} ${JSON.stringify(product.teshirtSize)}`;
          })
          .join(", ")
        : '',
      // order.instruction
      ""
    ])];

    // Convert data to sheet
    const worksheet = utils.aoa_to_sheet(worksheetData);
    utils.book_append_sheet(workbook, worksheet, "Orders");

    // Generate XLSX file
    const xlsxBuffer = write(workbook, { type: 'buffer', bookType: 'xlsx' });

    // Download the XLSX file
    const blob = new Blob([xlsxBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "orders.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <button onClick={downloadXlsx} className="btn btn-primary">Download</button>
    </div>
  );
};

export default ConvertJsonToXlsx;
